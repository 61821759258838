<template>
  <component class="molecule-default-card"
             :is="layoutOnly ? 'div' : 'v-card'"
             :class="{'no-title': !$slots.title && !title, 'layout-only': layoutOnly}"
             v-on="$listeners"
             v-bind="$attrs">
    <v-card-title v-if="$slots.title || title" class="card-title px-0 pt-0" :class="closeable ? 'pr-12' : ''">
      <slot name="before-title"></slot>
      <slot name="title">
        <h3>{{ title }}</h3>
      </slot>
      <slot name="after-title"></slot>
      <template v-if="closeable">
        <v-spacer></v-spacer>
        <v-btn class="close-button" v-bind="closeButtonAttrs" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text class="pa-0">
      <template v-if="closeable && !$slots.title && !title">
        <v-btn class="close-button" v-bind="closeButtonAttrs" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <div class="dialog-subtitle pb-2" v-if="subtitle || $slots.subtitle">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
      <slot name="default"></slot>
    </v-card-text>
    <v-card-actions v-if="$slots.actions" class="pa-0 pt-6 d-flex justify-end">
      <slot name="actions"></slot>
    </v-card-actions>
  </component>
</template>

<style scoped lang="scss">
@import '../../styles/typography';

.molecule-default-card {
  .close-button {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 8px;
  }

  .card-title {
    word-break: normal;
  }

  .dialog-subtitle {
    @include subtitle-1;
  }

  &.layout-only {
    border: none
  }
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  name: 'MoleculeDefaultCard',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    closeable: {
      type: Boolean
    },
    layoutOnly: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    closeButtonAttrs: {
      type: Object as PropType<Record<string, unknown>>
    }
  }
})
</script>
